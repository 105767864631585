var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('core-page-title'),_c('v-container',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.openDialog()}}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-plus')}}),_vm._v(" Adicionar ")],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"1"}},[_c('v-data-table',{attrs:{"disable-sort":"","server-items-length":_vm.count,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.events,"options":_vm.pagination,"items-per-page":20,"footer-props":{
              'items-per-page-options': [10, 20, 50, 75]
            }},on:{"update:options":[function($event){_vm.pagination=$event},_vm.loadEvents]},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date,'DD/MM/YYYY HH:mm'))+" ")]}},{key:"item.dateEnd",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.dateEnd,'DD/MM/YYYY HH:mm'))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","to":("/inscricao/" + (item.id)),"target":"_blank","title":"Link inscrição"}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-link-variant')}})],1),_c('v-btn',{attrs:{"icon":"","small":"","to":("/eventos/" + (item.id) + "/inscritos"),"title":"Lista de Inscritos"}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-account-group')}})],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openDialog(item)}}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-pencil')}})],1),_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-delete')},on:{"click":function($event){return _vm.clickDeleteItem(item)}}})],1)]}}],null,true)})],1)],1)],1)],1),_c('dialog-event',{attrs:{"event-id":_vm.editId},on:{"save":_vm.loadEvents},model:{value:(_vm.showDialogEvent),callback:function ($$v) {_vm.showDialogEvent=$$v},expression:"showDialogEvent"}}),_c('dialog-delete',{attrs:{"loading":_vm.loadingDelete},on:{"confirm":_vm.deleteItem},model:{value:(_vm.showDialogDelete),callback:function ($$v) {_vm.showDialogDelete=$$v},expression:"showDialogDelete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }