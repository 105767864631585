<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">

      <v-row>
        <v-col>
          <v-btn
            color="success"
            class="mr-2"
            dark
            @click="openDialog()"
          >
            <v-icon
              left
              v-text="'mdi-plus'"
            />
            Adicionar
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="events"
              :options.sync="pagination"
              :items-per-page="20"
              :footer-props="{
                'items-per-page-options': [10, 20, 50, 75]
              }"
              @update:options="loadEvents"
            >
              <template
                v-slot:[`item.date`]="{ item }"
              >
                {{ item.date | date('DD/MM/YYYY HH:mm') }}
              </template>

              <template
                v-slot:[`item.dateEnd`]="{ item }"
              >
                {{ item.dateEnd | date('DD/MM/YYYY HH:mm') }}
              </template>

              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  :to="`/inscricao/${item.id}`"
                  target="_blank"
                  title="Link inscrição"
                >
                  <v-icon
                    v-text="'mdi-link-variant'"
                    small
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                  :to="`/eventos/${item.id}/inscritos`"
                  title="Lista de Inscritos"
                >
                  <v-icon
                    v-text="'mdi-account-group'"
                    small
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                  @click="openDialog(item)"
                >
                  <v-icon
                    v-text="'mdi-pencil'"
                    small
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                >
                  <v-icon
                    v-text="'mdi-delete'"
                    small
                    @click="clickDeleteItem(item)"
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-event
      v-model="showDialogEvent"
      :event-id="editId"
      @save="loadEvents"
    />

    <dialog-delete
      v-model="showDialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
    />

  </div>
</template>

<script>
  import eventsApi from '@/api/events'

  export default {

    components: {
      DialogDelete: () => import('@/components/dialog/DialogDelete'),
      DialogEvent: () => import('@/components/dialog/events/DialogEvent'),
    },

    data () {
      return {
        loading: false,
        loadingDelete: false,
        showDialogEvent: false,
        showDialogDelete: false,
        count: 0,
        headers: [
          { text: '#', value: 'id' },
          { text: 'Nome', value: 'name' },
          { text: 'Data Início', value: 'date' },
          { text: 'Data Fim', value: 'dateEnd' },
          { text: '', value: 'actions', align: 'right' },
        ],
        events: [],
        pagination: {},
        editId: null,
        deleteId: null,
      }
    },

    methods: {
      async loadEvents () {
        try {
          this.loading = true

          const response = await eventsApi.list({
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
          })

          this.events = response.data.events
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      openDialog (event = null) {
        this.editId = event ? event.id : null
        this.showDialogEvent = true
      },

      async clickDeleteItem (event) {
        this.deleteId = event.id
        this.showDialogDelete = true
      },

      async deleteItem () {
        try {
          this.loadingDelete = true

          await eventsApi.delete(this.deleteId)

          this.loadEvents()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = false
          this.showDialogDelete = false
        }
      },

    },

  }
</script>
